"use client";
import { fetchHelper } from "@/api/fetch";
import CustomCard from "@/components/ui/CustomCard";
import SearchCardTitle from "@/components/ui/SearchCardTitle";
import SearchInput from "@/components/ui/SearchInput";
import { useSearchStore } from "@/store/useSearchStore";
import { useTranslations } from "next-intl";
import React, { useEffect, useState } from "react";

function SearchDirectionCard() {
  const { searchTermId, setSearchTermId } = useSearchStore();
  const t = useTranslations();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchOptions, setSearchOptions] = React.useState<SearchItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const debouncedSearch = async (term: string) => {
    if (!term) {
      setSearchOptions([]);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const response = await fetchHelper({
        method: "GET",
        endPoint: "/search",
        params: {
          city: term,
        },
      });

      setSearchOptions(response?.data || []);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Search failed");
      setSearchOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm]);

  return (

    <CustomCard className='gap-[3px] pb-[44px] relative'>

      {/* <p className='text-[11.75px] font-medium'>{t('Search for a destination')}</p> */}
      <SearchCardTitle title={t("Search for a destination")} />
      <SearchInput
        selectedOption={searchTermId}
        options={searchOptions || []}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setSelectedOption={setSearchTermId}
      />
    </CustomCard>
  );
}

export default SearchDirectionCard;
