'use client'
import CustomCard from '@/components/ui/CustomCard';
import { DateRangePicker } from '@/components/ui/DateRangePicker';
import { useSearchStore } from '@/store/useSearchStore';
function SearchDateCard() {
  const { date, setDate } = useSearchStore()
  return (
    <CustomCard className='w-full py-[25.5px]'>
      <DateRangePicker showTwoDates={true} date={date} setDate={setDate} />
    </CustomCard>
  )
}

export default SearchDateCard