'use client'
import { cn } from '@/lib/utils'
import React from 'react'

function CustomCard({children,className}:{
    children?:React.ReactNode,
    className?:string
}) {
  return (
    <div className={cn('px-[35px] text-Black border-[#F0F0F0] border-[3px] rounded-[15px] py-[26px] flex flex-col gap-[4px]',className)}>
        {children}
    </div>
  )
}

export default CustomCard