'use client'
import CommonSearchVisitors from '@/components/ui/CommonSearchVisitors'
import CustomCard from '@/components/ui/CustomCard'
import { useSearchStore } from '@/store/useSearchStore'

function SearchVisitorsCard() {
  const { adults, setAdults, children, setChildren } = useSearchStore()
  return (
    <CustomCard className='  pt-[21px] pb-[25px]'>
      <CommonSearchVisitors
        adults={adults}
        setAdults={setAdults}
        childrens={children}
        setChildren={setChildren}

      />
    </CustomCard>

  )
}

export default SearchVisitorsCard