import { DateRange } from 'react-day-picker'
import { create } from 'zustand'

export type Store = {
  searchTermId: number | undefined
  setSearchTermId: (search: number | undefined) => void
  adults: number
  setAdults: (adults: number) => void
  children: number
  setChildren: (children: number) => void
  date: DateRange | undefined,
  setDate: (date: DateRange | undefined) => void

}

export const useSearchStore = create<Store>()((set) => ({
  searchTermId: undefined,
  setSearchTermId: (search) => set({ searchTermId: search }),
  adults: 2,
  setAdults: (adults) => set({ adults }),
  children: 0,
  setChildren: (children) => set({ children }),
  date: undefined,
  setDate: (date) => set({ date })
}))

