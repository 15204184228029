import { useLocale, useTranslations } from "next-intl";
import React, { useEffect } from "react";
import { Input } from "./input";
import Popup from "./Popup";
import { IoIosClose } from "react-icons/io";
import { HiOutlineLocationMarker } from "react-icons/hi";

function SearchInput({
	searchTerm,
	setSearchTerm,
	options,
	setSelectedOption,
	selectedOption,
}: {
	searchTerm: string;
	setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
	options: { id: number; nameEn: string; nameAr: string }[];
	setSelectedOption: (id: number | undefined) => void;
	selectedOption: number | undefined;
}) {
	const t = useTranslations();
	const [isDropDownOpen, setIsDropDownOpen] = React.useState(false);
	const locale = useLocale();

	// Show dropdown only when there are options and no selection
	const shouldShowDropdown =
		options.length > 0 && searchTerm && !selectedOption;

	useEffect(() => {
		if (options.length && !selectedOption) {
			setIsDropDownOpen(true);
		}
	}, [options, selectedOption]);

	const handleOptionSelect = (option: {
		id: number;
		nameEn: string;
		nameAr: string;
	}) => {
		setSelectedOption(option.id);
		setSearchTerm(locale === "en" ? option.nameEn : option.nameAr);
		setIsDropDownOpen(false);
	};

	const handleClear = () => {
		setSelectedOption(undefined);
		setSearchTerm("");
		setIsDropDownOpen(false);
	};

	return (
		<div className="lg:relative">
			<div className="relative">
				<Input
					value={searchTerm}
					onChange={(e) => {
						setSearchTerm(e.target.value);
						setSelectedOption(undefined); // Clear selection when typing
					}}
					className="shadow-none border-none focus:border-none font-bold !text-[16px] !-ms-[12px] focus:outline-none focus:ring-0 h-[30px]"
					placeholder={t("Search for a destination")}
				/>
				{searchTerm && (
					<IoIosClose
						size={19}
						onClick={handleClear}
						className={`absolute ${locale === "ar" ? "left-3" : "right-3"} hover:text-Red top-2 cursor-pointer`}
					/>
				)}
			</div>

			{shouldShowDropdown && (
				<Popup
					isOpen={isDropDownOpen}
					setIsOpen={setIsDropDownOpen}
					className="max-h-[250px] gap-0 pt-[20px] pb-[10px] w-[100%] lg:w-[413px] items-center justify-center -start-0 lg:-start-[38px] top-[125px] lg:top-[80px] overflow-y-scroll"
				>
					{options.slice(0, 5).map((option) => (
						<button
							className="hover:bg-gray-100 flex items-center gap-[5px] rounded-md py-[10px] text-start mb-1 w-full ps-2"
							key={option.id}
							onClick={() => handleOptionSelect(option)}
						>
							<HiOutlineLocationMarker
								size={20}
								className="text-Red inline-block"
							/>
							<p className="font-bold text-[14px]">
								{locale === "en" ? option.nameEn : option.nameAr}
							</p>
						</button>
					))}
				</Popup>
			)}
		</div>
	);
}

export default SearchInput;
