"use client";
import { useAccountDataStore } from "@/store/useAccountData.store";
import { useTranslations } from "next-intl";

function HomeWelcomeSection({ isLogged }: { isLogged: boolean }) {
	const t = useTranslations();
	const { accountData } = useAccountDataStore();
	return (
		<div className="flex flex-col font-bold px-[43px] md:text-start text-center">
			<h1 className=" text-Red md:text-[45.75px] text-[30px]">
				{t("hello")} {isLogged && accountData.firstName}
			</h1>
			<h1 className=" text-Black ms-2  font-medium md:text-[28.75px] text-[18px]">
				{t("What is your next destination?")}{" "}
			</h1>
		</div>
	);
}

export default HomeWelcomeSection;
