'use client'
import { useToast } from '@/hooks/use-toast';
import { useSearchStore } from '@/store/useSearchStore';
import { useLocale, useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import React from 'react'
import { IoSearch } from "react-icons/io5";

function SearchButtonSection() {
  const { searchTermId, date, adults, children } = useSearchStore()
  const { toast } = useToast()
  const t = useTranslations()
  const router = useRouter()
  const locale = useLocale()
  const formatDate = (date: Date | undefined) => {
    if (!date) return '';
    return new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).format(date);
  };
  const search = () => {
    if (!searchTermId) {
      toast({
        title: t('pleaseSelectCity'),
        description: t('pleaseSelectCityDescription'),
        variant: 'destructive'
      });
      return;
    }
    const fromDate = formatDate(date?.from);
    const toDate = formatDate(date?.to);

    router.push(`/${locale}/search?searchTermId=${searchTermId}&fromDate=${fromDate}&toDate=${toDate}&adults=${adults}&children=${children}`);
  };

  return (
    <div className='w-full bottom-[-36px] flex items-center justify-center mx-auto absolute'>
      <button onClick={search} className='w-fit '>
        <div className='text-White  rounded-full bg-Red hover:bg-[#c91b00]  duration-300 font-bold w-[72px] h-[71px] flex items-center justify-center mx-auto'>
          <IoSearch size={30} />

        </div>
      </button>
    </div>
  )
}

export default SearchButtonSection